import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import theme from '../styles/theme'
import Img from 'gatsby-image'
import Button from './Button'

require('slick-carousel/slick/slick.css')
require('slick-carousel/slick/slick-theme.css')

const Post = styled.div`
  transition: background 0.2s;
  position: relative;
  width: 100%;
  margin: 0 auto 2vw auto;
  padding: 0 10px;
  height: 100%;

  .post-content {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.base};
    text-decoration: none;
    justify-content: flex-start;
    /* .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 100%;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        padding-bottom: ${props => (props.featured ? '100%' : '100%')};
      }
    } */
  }

  .post-properties {
    margin-top: 1.5em;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .end-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
  }

  .button-content {
    // width: 60%;
    margin: 2em 2em 0 0 ;
    & a {
      text-decoration: none;
    }
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    padding: 0 0.5em;
    .button-content {
      // width: 80%;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    padding: 0 0.6em;
  }
`

const PreviewImage = styled(Img)`
  min-height: 240px;
  max-height: 347px;
  margin-top: 0.5em;
`

const PreviewImageContent = styled.div`
  position: relative;

  .brand-logo-content {
    width: 100%;
    padding: 2% 8%;
    background: ${props => props.theme.colors.greenOverlay};
    position: absolute;
    z-index: 3;
    margin: auto 0;

    top: 0;
    bottom: 0;
    height: 20%;
  }
`

const Wrapper = styled.section`
  padding: ${props => props.padding || '4.3em'};
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  margin-bottom: ${props => props.marginBottom};
`

const Wrapper2 = styled.section`
  margin: 0 auto;
  // max-width: ${props => props.maxWidth}px;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  padding: 0 15px;
  margin-top: 0.5em;

  & h3.blog-title {
    font-family: Raleway;
    font-size: 28px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 33px;
    letter-spacing: 0.239186px;
    color: ${props => props.theme.colors.black};
    text-align: left;
    margin-top: 0.1em;
    margin-bottom: 0.5em;
  }

  & p.blog-excerpt {
    font-family: Raleway;
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: left;
    margin-top: 0.2em;
    color: ${props => props.theme.colors.black};
  }

  & .slick-list {
    padding: 0;
  }

  @media screen and (max-width: ${props => props.theme.breakpoint.extraLarge}px) {
    // padding: 0 4em;
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    // padding: 0 3em;
    & h3.blog-title { 
      font-size: 20px;
      line-height: 20px;
    }
    & p.blog-excerpt { 
      font-size: 16px;
      line-height: normal;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    // padding: 0 0;
    margin-top: 3em;

    .slick-list {
      padding: 0px 40px !important;
    }

    & h3.blog-title { 
      font-size: 28px;
      line-height: 33px;
    }
    & p.blog-excerpt { 
      font-size: 20px;
      line-height: normal;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.small}px) {
    // padding: 0 0;
    & h3.blog-title { 
      font-size: 18px;
      line-height: 24px;
    }
    & p.blog-excerpt { 
      font-size: 12px;
      line-height: normal;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  padding: 1.3em 25px 0;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 125%;
  text-align: left;
  letter-spacing: 0.177083px;
  color: ${props => props.theme.colors.darkGrey};

  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: auto;

  &.big-title {
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: ${props => props.theme.colors.tertiary};
    margin-bottom: 1em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoint.small}px) {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.43px;
    padding-left: 1em;
    padding-right: 1em;

    &.subtitle {
      font-size: 24px; 
      line-height: 125%;
    }

    &.big-title {
      font-size: 22px;
      line-height: 26px;
      text-align: left;
      color: ${props => props.theme.colors.tertiary};
    }
  }
`

const ListPosts = props => {
  // const maxWidthSlider = (slidesRowlength * 347) + ((slidesRowlength - 1) * 30 )

  return (
    <Wrapper marginBottom={props.marginBottom ? props.marginBottom : '0px'} padding={props.padding || '4.3em 0 0'}>
      <Title className={`subtitle ${props.bigTitle ? 'big-title' : ''}`} >{props.title || ''}</Title>
      <Wrapper2>
        <div className="row">
          {props.posts.map(({ node: inf }, index) => (
            <div key={`homePost-${inf.id}`} className="col-md-4 mb-5 pb-3">
              <Post>
                <div className="post-content">
                  <Link to={`/blog/${inf.slug}`}>
                    <PreviewImageContent>
                      <PreviewImage fluid={inf.heroImage.fluid} />
                    </PreviewImageContent>
                  </Link>
                  <div className="post-properties">
                    <div className="title-content">
                      <h3 className="blog-title">{inf.title}</h3>
                      <p className="blog-excerpt">{inf.excerpt}</p>
                    </div>
                    <div className="end-content">
                      <div className="button-content">
                        <Link to={`/blog/${inf.slug}`}>
                          <Button padding="9px 25px">READ MORE</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Post>
            </div>
          ))}
        </div>
      </Wrapper2>
    </Wrapper>
  )
}

export default ListPosts
