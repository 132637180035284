import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import styled from 'styled-components'
import config from '../utils/siteConfig'
import LinkBack from '../components/LinkBack'
import PostBody from '../components/PostBody'
import ListPosts from '../components/ListPosts'

const Wrapper = styled.section`
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: auto;
  margin-top: 155px;

  .button-content {
    & a {
      text-decoration: none;
    }
  }
`
const Title = styled.h1`
  padding: 0 25px;
  width: 100%;
  font-family: Raleway;
  font-style: normal;
  font-weight: 200;
  line-height: 56px;
  font-size: 48px;
  letter-spacing: 0.177083px;
  color: ${props => props.theme.colors.tertiary};
  text-align: left;
  margin-bottom: 32px;

  @media screen and (max-width: 600px) {
    line-height: 48px;
    font-size: 38px;
    letter-spacing: 0.43px;

    &.subtitle {
      font-size: 24px; 
      line-height: 125%;
    }
  }
  @media screen and (max-width: 350px) {
    line-height: 38px;
    font-size: 28px;
    letter-spacing: 0.43px;

    &.subtitle {
      font-size: 24px; 
      line-height: 125%;
    }
  }
`
const DateContent = styled.h4`
  padding: 0 25px;
  width: 100%;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-size: 18px;
  letter-spacing: 0.0727273px;
  color: ${props => props.theme.colors.black};
  text-align: left;
  margin-bottom: 100px;

  @media screen and (max-width: 600px) {
    line-height: 48px;
    font-size: 38px;
    letter-spacing: 0.43px;

    &.subtitle {
      font-size: 24px; 
      line-height: 125%;
    }
  }
  @media screen and (max-width: 350px) {
    line-height: 38px;
    font-size: 28px;
    letter-spacing: 0.43px;

    &.subtitle {
      font-size: 24px; 
      line-height: 125%;
    }
  }
`

const PostPageTemplate = ({ data }) => {
  const content = data.contentfulPost
  const posts = data.allContentfulPost.edges || []
  
  return (
    <Layout whiteNav>
      <Helmet>
        <link 
          rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap-grid.min.css" 
          integrity="sha256-D9AvR0EzkNZoWZVSvI3ch+uf/Z5izrIpcbsykVphwPU=" 
          crossOrigin="anonymous" 
        />
        <title>{`${config.siteTitle}`}</title>
      </Helmet>

      <Wrapper>
        <LinkBack
          alt="Back to blog page"
          title="BACK TO BLOG PAGE"
          linkTo="/blog"
        />
        <Title>
          {content.title}
        </Title>
        <DateContent>
          {content.publishDate}
        </DateContent>
        <PostBody 
          body={content.body}
        />
        <ListPosts
          title={"MORE FROM EMBELLO"}
          posts={posts}
          padding="0 0 60px"
          bigTitle
        />
      </Wrapper>
      
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      id
      slug
      title
      publishDate(formatString: "MMMM DD, YYYY")
      heroImage {
        title
        fluid(maxWidth: 800, maxHeight: 800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt
        }
      }
      metaDescription {
        internal {
          content
        }
      }
      excerpt
    }
    allContentfulPost(
      sort: { fields: [updatedAt], order: DESC }
      limit: 3
      filter: { slug: { ne: $slug } }
    ) {
      edges {
        node {
          id
          slug
          title
          publishDate
          heroImage {
            title
            fluid(maxWidth: 800, maxHeight: 800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt
            }
          }
          metaDescription {
            internal {
              content
            }
          }
          excerpt
        }
      }
    }
  }
`

export default PostPageTemplate
